import React from 'react';
import Logo from '../logo.png';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Container, Grid, Card, CardContent, Divider, CardHeader, CardActions, Button } from '@material-ui/core';
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import { Redirect } from "react-router-dom";

import Copyright from './Copyright';

const buttonColor = (color) => createMuiTheme({
    palette: {
        primary: {
            main: color
        }
    },
    typography: {
        fontFamily: 'Roboto'
    }
});

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
        fontWeight: 500
    },
    a: {
        height: 37.39,
        [theme.breakpoints.up('sm')]: {
            height: 45.89
        },
    },
    logo: {
        width: 110,
        [theme.breakpoints.up('sm')]: {
            width: 135
        },
        marginRight: theme.spacing(4)
    }
}));

function Index(props) {
    const { theme } = props;
    const classes = useStyles();

    return (
        <div className='Índice'>
            <header className='Índice-header'>
                <AppBar position='static'>
                    <Toolbar>
                        <a className={classes.a} href={'https://www.centronacionaldeconsultoria.com/'}>
                            <img src={Logo} alt='logo' className={classes.logo} />
                        </a>
                        <Typography style={{ marginRight: theme.spacing(2), color: 'inherit', textDecoration: 'none' }} variant='h5' className={classes.title}>
                            Índice de aplicativos
                        </Typography>
                    </Toolbar>
                </AppBar>
            </header>
            <div className='Índice-body' style={{ marginTop: theme.spacing(2) }}>
                <Redirect to={{ pathname: '/', state: { from: props.location } }} />
                <Container component='main' maxWidth='lg'>
                    <Grid
                        container
                        direction='row'
                        alignItems='stretch'
                        spacing={2}
                    >
                        <Grid item xs={12} sm={6} md={4}>
                            <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                <CardHeader
                                    style={{ backgroundColor: '#263238', paddingTop: 8, paddingBottom: 8 }}
                                    title={
                                        <Typography variant='h6' style={{ color: 'white' }}>Perfil de usuario</Typography>
                                    }
                                />
                                <Divider />
                                <CardContent style={{ paddingBottom: 8 }}>
                                    <Typography variant='body2' align='justify'>Este es el aplicativo de perfil de usuario, a través del cual los usuarios del Sistema CNC pueden gestionar la información de su perfil, sumado a un control de seguridad en el que pueden cambiar su contraseña actual, o cerrar todas las sesiones abiertas.</Typography>
                                </CardContent>
                                <CardActions style={{ justifyContent: 'flex-end', paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2), marginTop: 'auto' }}>
                                    <ThemeProvider theme={responsiveFontSizes(buttonColor('#263238'))}>
                                        <Button component='a' variant='contained' color='primary' size='small' href='https://perfil.desarrolloscnc.com'>Ir</Button>
                                    </ThemeProvider>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                <CardHeader
                                    style={{ backgroundColor: '#FF9800', paddingTop: 8, paddingBottom: 8 }}
                                    title={
                                        <Typography variant='h6'>Aplicativo de selección</Typography>
                                    }
                                />
                                <Divider />
                                <CardContent style={{ paddingBottom: 8 }}>
                                    <Typography variant='body2' align='justify'>Este es el aplicativo de selección, a través del cual los interesados en trabajar en el área de producción del CNC realizan el proceso para ser considerados como candidatos.</Typography>
                                </CardContent>
                                <CardActions style={{ justifyContent: 'flex-end', paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2), marginTop: 'auto' }}>
                                    <ThemeProvider theme={responsiveFontSizes(buttonColor('#FF9800'))}>
                                        <Button component='a' variant='contained' color='primary' size='small' href='https://seleccion.desarrolloscnc.com'>Ir</Button>
                                    </ThemeProvider>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                <CardHeader
                                    style={{ backgroundColor: '#C70039', paddingTop: 8, paddingBottom: 8 }}
                                    title={
                                        <Typography variant='h6' style={{ color: 'white' }}>Gestor de selección</Typography>
                                    }
                                />
                                <Divider />
                                <CardContent style={{ paddingBottom: 8 }}>
                                    <Typography variant='body2' align='justify'>Este es el aplicativo de gestor de selección, a través del cual el Área de Selección del CNC tiene control y trazabilidad del proceso de reclutamiento y contratación del personal registrado en el Aplicativo de Selección.</Typography>
                                </CardContent>
                                <CardActions style={{ justifyContent: 'flex-end', paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2), marginTop: 'auto' }}>
                                    <ThemeProvider theme={responsiveFontSizes(buttonColor('#C70039'))}>
                                        <Button component='a' variant='contained' color='primary' size='small' href='https://gestor.seleccion.desarrolloscnc.com'>Ir</Button>
                                    </ThemeProvider>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                <CardHeader
                                    style={{ backgroundColor: '#6A1B9A', paddingTop: 8, paddingBottom: 8 }}
                                    title={
                                        <Typography variant='h6' style={{ color: 'white' }}>Registro de datos para pagos</Typography>
                                    }
                                />
                                <Divider />
                                <CardContent style={{ paddingBottom: 8 }}>
                                    <Typography variant='body2' align='justify'>Este es el aplicativo de registro de personal, a través del cual los nuevos encuestadores crean su usuario con la información de pagos correspondiente.</Typography>
                                </CardContent>
                                <CardActions style={{ justifyContent: 'flex-end', paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2), marginTop: 'auto' }}>
                                    <ThemeProvider theme={responsiveFontSizes(buttonColor('#6A1B9A'))}>
                                        <Button component='a' variant='contained' color='primary' size='small' href='https://registro.desarrolloscnc.com'>Ir</Button>
                                    </ThemeProvider>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                <CardHeader
                                    style={{ backgroundColor: '#880E4F', paddingTop: 8, paddingBottom: 8 }}
                                    title={
                                        <Typography variant='h6' style={{ color: 'white' }}>Gestor de personal</Typography>
                                    }
                                />
                                <Divider />
                                <CardContent style={{ paddingBottom: 8 }}>
                                    <Typography variant='body2' align='justify'>Este es el aplicativo de gestión de personal, a través del cual se realiza la actualización del tipo de contrato, tipo de campo y estado de un encuestador.</Typography>
                                </CardContent>
                                <CardActions style={{ justifyContent: 'flex-end', paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2), marginTop: 'auto' }}>
                                    <ThemeProvider theme={responsiveFontSizes(buttonColor('#880E4F'))}>
                                        <Button component='a' variant='contained' color='primary' size='small' href='https://gestor.personal.desarrolloscnc.com'>Ir</Button>
                                    </ThemeProvider>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                <CardHeader
                                    style={{ backgroundColor: '#004D40', paddingTop: 8, paddingBottom: 8 }}
                                    title={
                                        <Typography variant='h6' style={{ color: 'white' }}>Gestor de estudios</Typography>
                                    }
                                />
                                <Divider />
                                <CardContent style={{ paddingBottom: 8 }}>
                                    <Typography variant='body2' align='justify'>Este es el aplicativo de gestión de estudios, a través del cual se realiza la creación, actualización y eliminación de los estudios utilizados en los demás aplicativos del Sistema CNC.</Typography>
                                </CardContent>
                                <CardActions style={{ justifyContent: 'flex-end', paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2), marginTop: 'auto' }}>
                                    <ThemeProvider theme={responsiveFontSizes(buttonColor('#004D40'))}>
                                        <Button component='a' variant='contained' color='primary' size='small' href='https://gestor.estudios.desarrolloscnc.com'>Ir</Button>
                                    </ThemeProvider>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                <CardHeader
                                    style={{ backgroundColor: '#2E7D32', paddingTop: 8, paddingBottom: 8 }}
                                    title={
                                        <Typography variant='h6' style={{ color: 'white' }}>Control de productividad - Presencial</Typography>
                                    }
                                />
                                <Divider />
                                <CardContent style={{ paddingBottom: 8 }}>
                                    <Typography variant='body2' align='justify'>Este es el aplicativo de control de productividad para <span style={{ fontWeight: 500 }}>campo presencial</span>, a través del cual los coordinadores registran las actividades (diferente a las encuestas) realizadas por los encuestadores y que deben ser pagadas por horas.</Typography>
                                </CardContent>
                                <CardActions style={{ justifyContent: 'flex-end', paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2), marginTop: 'auto' }}>
                                    <ThemeProvider theme={responsiveFontSizes(buttonColor('#2E7D32'))}>
                                        <Button component='a' variant='contained' color='primary' size='small' href='https://productividad.presencial.desarrolloscnc.com'>Ir</Button>
                                    </ThemeProvider>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                <CardHeader
                                    style={{ backgroundColor: '#0D47A1', paddingTop: 8, paddingBottom: 8 }}
                                    title={
                                        <Typography variant='h6' style={{ color: 'white' }}>Control de productividad - Telefónico</Typography>
                                    }
                                />
                                <Divider />
                                <CardContent style={{ paddingBottom: 8 }}>
                                    <Typography variant='body2' align='justify'>Este es el aplicativo de control de productividad para <span style={{ fontWeight: 500 }}>campo telefónico</span>, a través del cual los coordinadores registran las actividades (diferente a las encuestas) realizadas por los encuestadores y que deben ser pagadas por horas.</Typography>
                                </CardContent>
                                <CardActions style={{ justifyContent: 'flex-end', paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2), marginTop: 'auto' }}>
                                    <ThemeProvider theme={responsiveFontSizes(buttonColor('#0D47A1'))}>
                                        <Button component='a' variant='contained' color='primary' size='small' href='https://productividad.telefonico.desarrolloscnc.com'>Ir</Button>
                                    </ThemeProvider>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                <CardHeader
                                    style={{ backgroundColor: '#D84315', paddingTop: 8, paddingBottom: 8 }}
                                    title={
                                        <Typography variant='h6' style={{ color: 'white' }}>Legalizaciones</Typography>
                                    }
                                />
                                <Divider />
                                <CardContent style={{ paddingBottom: 8 }}>
                                    <Typography variant='body2' align='justify'>Este es el aplicativo de legalizaciones, a través del cual se realiza todo el control relacionado con la asignación de anticipos a un supervisor/encuestador y su posterior legalización.</Typography>
                                </CardContent>
                                <CardActions style={{ justifyContent: 'flex-end', paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2), marginTop: 'auto' }}>
                                    <ThemeProvider theme={responsiveFontSizes(buttonColor('#D84315'))}>
                                        <Button component='a' variant='contained' color='primary' size='small' href='https://legalizaciones.desarrolloscnc.com'>Ir</Button>
                                    </ThemeProvider>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                <CardHeader
                                    style={{ backgroundColor: '#4E342E', paddingTop: 8, paddingBottom: 8 }}
                                    title={
                                        <Typography variant='h6' style={{ color: 'white' }}>Reportes de producción</Typography>
                                    }
                                />
                                <Divider />
                                <CardContent style={{ paddingBottom: 8 }}>
                                    <Typography variant='body2' align='justify'>Este es el aplicativo de reportes de producción, a través del cual se puede consultar información relacionada con el avance en muestra y costos de los estudios en ejecución.</Typography>
                                </CardContent>
                                <CardActions style={{ justifyContent: 'flex-end', paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2), marginTop: 'auto' }}>
                                    <ThemeProvider theme={responsiveFontSizes(buttonColor('#4E342E'))}>
                                        <Button component='a' variant='contained' color='primary' size='small' href='https://reportes.produccion.desarrolloscnc.com'>Ir</Button>
                                    </ThemeProvider>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                <CardHeader
                                    style={{ backgroundColor: '#3498DB', paddingTop: 8, paddingBottom: 8 }}
                                    title={
                                        <Typography variant='h6' style={{ color: 'white' }}>Aplicativo de logística</Typography>
                                    }
                                />
                                <Divider />
                                <CardContent style={{ paddingBottom: 8 }}>
                                    <Typography variant='body2' align='justify'>Este es el aplicativo de logística, a través del cual se realiza el inventario y asignación de implementos necesarios para el desarrollo de actividades en campo presencial y telefónico.</Typography>
                                </CardContent>
                                <CardActions style={{ justifyContent: 'flex-end', paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2), marginTop: 'auto' }}>
                                    <ThemeProvider theme={responsiveFontSizes(buttonColor('#3498DB'))}>
                                        <Button component='a' variant='contained' color='primary' size='small' href='https://logistica.desarrolloscnc.com'>Ir</Button>
                                    </ThemeProvider>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className='Copyrighyt'>
                <Copyright />
            </div>
        </div>
    );
}

export default Index;